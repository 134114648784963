import React from "react";
import image from "../../../assets/daigramMonet.png";
import { BottomToTopArrow } from "../../../assets/icons/DiagramMonetIcons/BottomToTopArrow";
import ThreeLines from "../../../assets/icons/DiagramMonetIcons/Threelines";

const AiModalSection = ({
  aiData,
  modelPredictionIcon,
  modelPredictionTitle,
  modalHeading,
}) => {
  return (
    <div className="flex flex-row-reverse sm:flex-col gap-1.5 sm:gap-2.5 items-center justify-end pb-7 lg:pb-0 lg:justify-start relative z-10">
      <div>
        <p className="w-full max-w-fit px-3 py-1 text-sm font-medium text-center rounded-[60px] bg-blue-1600 font-inter -tracking-wider mb-3">
          {modalHeading}
        </p>
        <div className="max-w-fit grid grid-cols-2 gap-4 px-2.5 py-3.5 rounded modal_ai_images_backgroud">
          {aiData?.map((value, index) => {
            return <img key={index} src={value?.Icon} alt={value?.alt || "Ai Modal Icon"} />;
          })}
        </div>
      </div>
      <BottomToTopArrow />
    

      {/* <img src={image} className="h-5 rotate-90 sm:rotate-0 sm:h-14" /> */}
      <ThreeLines/>
      <div className="flex flex-col items-center justify-center px-2.5 py-3.5 rounded max-w-fit model_prediction_bg z-10 ">
        <div className="flex flex-col justify-center mx-auto">
          <img className="w-6 mx-auto" src={modelPredictionIcon}  alt="model prediction icon"/>
          <p className="text-base font-medium text-center max-w-24 leading-6 traking-[-0.176px]">
            {modelPredictionTitle}
          </p>
        </div>
        <div className="flex flex-col w-full gap-3 mt-3 animate-pulse">
          <div className="w-full h-2 rounded-full bg-gray-1400" />
          <div className="h-2 bg-gray-2200 rounded-full w-[60%]" />
          <div className="h-2 bg-gray-1400 rounded-full w-[80%]" />
        </div>
      </div>
    </div>
  );
};

export default AiModalSection;
