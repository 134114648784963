import React, { useContext } from "react";
import ModalContext from "../../../Contexts/openModal";
import SingleEmailForm2 from "../../Pieces/SingleEmailForm2";

const FooterBanner = ({ slice, location }) => {
  const { setEmail, toggleModal } = useContext(ModalContext);
  const dark = "bg-purple-2300";
  return (
    <>
      {!location?.pathname === "/" && (
        <article className={`relative ${dark} `}>
          <section className="relative mx-5 max-w-7xl xl:px-16 xl:mx-auto sm:mx-6 ">
            <section className="max-w-lg mx-auto text-center text-gray-2800 lg:max-w-3xl lg:px-8 py-36">
              <h3 className="font-bold font-manrope lg:text-4.5xl sm:text-3.25xl text-2xl lg:mb-6 sm:mb-7 mb-5">
                {slice?.primary?.cta_heading?.text}
              </h3>
              <p className="mb-8 text-sm font-inter sm:text-lg sm:mb-7">
                {slice?.primary?.cta_description?.text}
              </p>
              <div className="flex justify-center">
                <SingleEmailForm2
                  border={dark}
                  openModal={toggleModal}
                  setEmail={setEmail}
                  formHsClass={slice?.primary?.hs_class?.text}
                  formId={slice?.primary?.hs_id?.text}
                  buttonText={slice?.primary?.cta_text?.text}
                />
              </div>
            </section>
          </section>
        </article>
      )}
    </>
  );
};
export default FooterBanner;
