import React, { useContext, useEffect, useState } from "react";
import ModalContext from "../../Contexts/openModal";
import DemoModal from "../DemoModal";
import CTAPicasso from "./CTA Picasso";
import CareerBenefits from "./CareerBenefits";
import Company from "./Company";
import DiagramMonet from "./DiagramMonet";
import FeatureDali from "./FeatureDali";
import FeatureDegard from "./FeatureDegard";
import FeatureKlimt from "./FeatureKlimt";
import FeatureMatisse from "./FeatureMatisse";
import FeatureMonet from "./FeatureMonet";
import { FeatureMunch } from "./FeatureMunch";
import FeaturePicasso from "./FeaturePicasso";
import FeatureRaphael from "./FeatureRaphael";
import FeatureTurner from "./FeatureTurner";
import FooterBanner from "./FooterBanner";
import FooterRaphael from "./FooterRaphael";
import FormKusama from "./FormKusama";
import FormMonet from "./FormMonet";
import Formcc96010fd9a642e1a736c9ce8ffc8a78Monet from "./Formcc96010fd9a642e1a736c9ce8ffc8a78Monet";
import { HeroDali } from "./HeroDali";
import HeroKusama from "./HeroKusama";
import HeroMonet from "./HeroMonet";
import HeroRaphael from "./HeroRaphael";
import HeroSupportingAssetMonet from "./HeroSupportingAssetMonet";
import JobListingMonet from "./JobListingMonet";
import LogosDali from "./LogosDali";
import { LogosMonet } from "./LogosMonet";
import { SectionDali } from "./SectionDali";
import SectionKahlo from "./SectionKahlo";
import SectionKusama from "./SectionKusama";
import { SectionMonet } from "./SectionMonet";
import SectionPollock from "./SectionPollock";
import SectionVinci from "./SectionVinci";
import SectionWarhol from "./SectionWarhol";
import StatsMonet from "./StatsMonet";
import TestimonialMonet from "./TestimonialMonet";
import TestimonialRaphael from "./TestimonialRaphael";
import TestimonialTurner from "./TestimonialTurner";
import HomepageQuiz from "./HomepageQuiz";
import CellMonet from "./CellMonet";
import ImageMonet from "./ImageMonet";

const allSlices = {
  feature_monet: (slice) => <FeatureMonet {...{ slice }} />,
  hero_monet: (slice, location) => <HeroMonet {...{ slice, location }} />,
  testimonial_turner: (slice) => <TestimonialTurner {...{ slice }} />,
  testimonial_raphael: (slice) => <TestimonialRaphael {...{ slice }} />,
  testimonial_monet: (slice) => <TestimonialMonet {...{ slice }} />,
  feature_turner: (slice) => <FeatureTurner {...{ slice }} />,
  feature_raphael: (slice) => <FeatureRaphael {...{ slice }} />,
  feature_dali: (slice, location) => <FeatureDali {...{ slice, location }} />,
  cta_picasso: (slice) => <CTAPicasso {...{ slice }} />,
  hero_supporting_asset_monet: (slice) => (
    <HeroSupportingAssetMonet {...{ slice }} />
  ),
  hero_raphael: (slice) => <HeroRaphael {...{ slice }} />,
  feature_klimt: (slice) => <FeatureKlimt {...{ slice }} />,
  feature_matisse: (slice) => <FeatureMatisse {...{ slice }} />,
  feature_degard: (slice) => <FeatureDegard {...{ slice }} />,
  career_jobs: (slice) => <JobListingMonet {...{ slice }} />,
  career_benifets: (slice) => <CareerBenefits {...{ slice }} />,
  company: (slice, location) => <Company {...{ slice, location }} />,
  feature_picasso: (slice, location) => (
    <FeaturePicasso {...{ slice, location }} />
  ),
  logos_monet: (slice, location) => <LogosMonet {...{ slice, location }} />,
  section_dali: (slice) => <SectionDali {...{ slice }} />,
  section_monet: (slice) => <SectionMonet {...{ slice }} />,
  hero_dali: (slice, location) => <HeroDali {...{ slice, location }} />,
  feature_munch: (slice, location) => <FeatureMunch {...{ slice, location }} />,
  section_kusama: (slice) => <SectionKusama {...{ slice }} />,
  stats_monet: (slice, location) => <StatsMonet {...{ slice, location }} />,
  section_pollock: (slice, location) => (
    <SectionPollock {...{ slice, location }} />
  ),
  hero_kusama: (slice, location) => <HeroKusama {...{ slice, location }} />,
  section_kahlo: (slice, location) => <SectionKahlo {...{ slice, location }} />,
  diagram_monet: (slice, location) => <DiagramMonet {...{ slice, location }} />,
  footer_banner: (slice, location) => <FooterBanner {...{ slice, location }} />,
  footer_raphael: (slice, location) => (
    <FooterRaphael {...{ slice, location }} />
  ),
  logos_dali: (slice, location) => <LogosDali {...{ slice, location }} />,
  form_monet: (slice, location) => <FormMonet {...{ slice, location }} />,
  form_cc96010fd9a642e1a736c9ce8ffc8a78_monet: (slice, location) => (
    <Formcc96010fd9a642e1a736c9ce8ffc8a78Monet {...{ slice, location }} />
  ),
  form_kusama: (slice, location) => <FormKusama {...{ slice, location }} />,
  section_vinci: (slice, location) => <SectionVinci {...{ slice, location }} />,
  section_warhol: (slice, location) => (
    <SectionWarhol {...{ slice, location }} />
  ),
  homepage_quiz: (slice, location) => <HomepageQuiz {...{ slice, location }} />,
  cell_monet: (slice, location) => <CellMonet {...{ slice, location }} />,
  image_monet: (slice, location) => <ImageMonet {...{ slice, location }} />,
  quiz_result: null,
  homepage_quizform: null,
};

const Slices = ({ slices, location }) => {
  const { isOpen, toggleModal, email } = useContext(ModalContext);

  const [quizSlices, setQuizSlices] = useState([]);
  const [quizResults, setQuizResults] = useState([]);
  const [quizForms, setQuizForms] = useState([]);

  useEffect(() => {
    // Filter each slice type separately
    const newQuizSlices =
      slices?.prismicPageV3?.data?.body
        ?.filter((slice) => slice.slice_type === "homepage_quiz")
        ?.map((slice) => slice) || [];

    const newQuizResults =
      slices?.prismicPageV3?.data?.body
        ?.filter((slice) => slice.slice_type === "quiz_result")
        ?.map((slice) => slice) || [];

    const newQuizForms =
      slices?.prismicPageV3?.data?.body
        ?.filter((slice) => slice.slice_type === "homepage_quizform")
        ?.map((slice) => slice) || [];

    setQuizSlices(newQuizSlices);
    setQuizResults(newQuizResults);
    setQuizForms(newQuizForms);
  }, [slices]);

  const quizIndex = slices?.prismicPageV3?.data?.body?.findIndex(
    (slice) => slice.slice_type === "homepage_quiz"
  );

  return (
    <>
      <div>
        {slices?.prismicPageV3?.data?.body?.map((slice, index) => {
          const renderer = allSlices[slice?.slice_type];
          const isQuizSpot = index === quizIndex;

          if (
            slice?.slice_type === "quiz_result" ||
            slice?.slice_type === "homepage_quizform"
          ) {
            return null;
          }

          return (
            <React.Fragment key={`slice_${slice?.id}`}>
              <div
                className={`${index === 0 && slice.slice_type !== "hero_raphael" ? "pt-[88px] sm:pt-32" : ""}`}
              >
                {renderer ? renderer(slice, location, toggleModal) : null}
              </div>

              {isQuizSpot && quizSlices.length > 0 && (
                <HomepageQuiz
                  slices={quizSlices}
                  quizResults={quizResults}
                  quizForms={quizForms}
                  location={location}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
      <DemoModal
        title={`Get started`}
        isOpen={isOpen}
        email={email}
        closeModal={toggleModal}
        location={location}
      />
    </>
  );
};

export default Slices;
