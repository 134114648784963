import React, { useContext, useEffect, useState, useRef } from "react";
import { navigate } from "gatsby-link";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useMediaQuery } from "react-responsive";
import ModalContext from "../../../Contexts/openModal";
import HeadingSection from "../../Pieces/HeadingSection";
import VideoComponent from "../../VideoComponent";

const FeatureTurner = ({ slice }) => {
  const [activeProduct, setActiveProduct] = useState(0);
  const [target, setTarget] = useState(null);
  const isMobile = useMediaQuery({ minWidth: 475 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const { toggleModal } = useContext(ModalContext);
  const intervalRef = useRef(null);

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setActiveProduct((prevIndex) =>
        prevIndex === slice.items.length - 1 ? 0 : prevIndex + 1
      );
    }, 9000);
  };

  const clearExistingInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    startInterval();
    return () => clearExistingInterval();
  }, [slice.items.length]);

  let image = getImage(slice?.items[activeProduct]?.cell_image);

  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className="py-10 lg:pt-20 lg:pb-0 lg:mx-11">
        <HeadingSection data={slice?.primary} toggleModal={toggleModal}   headingStyle="sm:text-3.25xl sm:leading-10 sm:!-tracking-[0.9px]" />
        <section
          className={`flex flex-col gap-10 mt-6 ${
            slice?.primary?.layout_style === "left"
              ? "lg:flex-row"
              : "lg:flex-row-reverse"
          } overflow-hidden`}
        >
          <div className={`cursor-pointer lg:w-[540px] text-left flex-1`}>
            <div className="flex flex-col gap-10 md:justify-start xl:pb-0 lg:pb-5">
              {slice?.items?.map((item, index) => (
                <div
                  className={`group gap-2.5 ease-linear hover: ${
                    activeProduct !== index
                      ? "bg-transparent xl:w-[448px]"
                      : `lg:pl-[10px] lg:border-l-4 lg:border-purple-2100 xl:w-[484px]`
                  }`}
                  key={index}
                  onClick={() => {
                    isDesktop
                      ? item.cta_link?.url && navigate(item.cta_link?.url)
                      : setActiveProduct(index);
                  }}
                  onMouseEnter={() => {
                    setTarget(item.cell_title?.text);
                    clearExistingInterval();
                    setActiveProduct(index);
                  }}
                  onMouseLeave={() => {
                    setTarget(null);
                    startInterval();
                  }}
                >
                  <div className={``}>
                    <div
                      className={`flex ${
                        slice?.primary?.cell_direction === "row"
                          ? "flex-col"
                          : "flex-col-reverse"
                      } gap-2.5`}
                    >
                      <div
                        className={`w-full gap-2.5 ${
                          activeProduct !== index
                            ? "bg-transparent"
                            : `pl-[22px] border-l-4 border-purple-2100 lg:border-none`
                        }`}
                      >
                        <div className="flex py-1.5">
                          <h3
                            className={`text-xl font-semibold ${
                              activeProduct === index
                                ? "text-purple-1100"
                                : "text-gray-2500"
                            } font-manrope tracking-tightest`}
                          >
                            {item?.cell_title?.text}
                          </h3>
                        </div>
                        <p
                          className={`font-inter text-base tracking-tight_1  ${
                            activeProduct === index
                              ? "text-gray-2500"
                              : "text-gray-1200"
                          }`}
                        >
                          {item?.cell_description?.text}
                        </p>
                      </div>
                      <div
                        className={`lg:hidden pt-5  ${
                          activeProduct === index
                            ? "block lg:block"
                            : "hidden lg:block"
                        }`}
                        data-aos="fade-up"
                      >
                        {slice?.items[activeProduct]?.video_link?.url ? (
                          <VideoComponent
                            loop
                            autoPlay
                            muted
                            className={`rounded-2xl border-[0.3px] flex-shrink-0 border-gray-1600 aspect-square h-full w-full`}
                          >
                            <source
                              src={slice?.items[activeProduct]?.video_link?.url}
                              type="video/mp4"
                            />
                          </VideoComponent>
                        ) : (
                          <img
                            key={slice?.items[activeProduct]?.cell_image?.url}
                            src={slice?.items[activeProduct]?.cell_image?.url}
                            alt={
                              slice?.items[activeProduct]?.cell_image?.alt || "cell_image"
                            }
                            loading="lazy"
                            className={`rounded-2xl border-[0.3px] flex-shrink-0 object-contain border-gray-1600 aspect-square h-full w-full`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <section
            className="max-w-[480px] xl:max-w-[580px] aspect-square h-full w-full lg:block hidden"
            data-aos="zoom-y-out"
          >
            {slice?.items[activeProduct]?.video_link?.url ? (
              // <div className="justify-center xl:px-7.5 py-10 lg:px-5 p-14 duration-[1s] ease-in lg:flex hidden justify-self-center m-auto">
              <VideoComponent
                loop
                autoPlay
                muted
                className="w-full h-full rounded-2xl"
                data-aos="zoom-y-out"
              >
                <source
                  src={slice?.items[activeProduct]?.video_link?.url}
                  type="video/mp4"
                />
              </VideoComponent>
            ) : (
              <div data-aos="zoom-y-out" className="w-full h-full rounded-2xl">
                <img
                  src={slice?.items[activeProduct]?.cell_image?.url}
                  alt={slice?.items[activeProduct]?.cell_image?.alt || "cell_image"}
                  loading="lazy"
                  className="object-contain w-full h-full rounded-2xl"
                />
              </div>
            )}
          </section>
        </section>
      </article>
    </section>
  );
};

export default FeatureTurner;
